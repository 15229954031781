import {
  takeEvery,
  fork,
  put,
  all,
  call,
  takeLeading,
  take,
  cancel,
  takeLatest,
  select,
} from 'redux-saga/effects';
import types from './actionTypes';
import * as callsActions from './actions';
import {
  isOperator as isOperatorSelector,
  userAcquisitionOrderIds,
} from '../../selectors/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  getFirestore,
  collection,
  doc,
  query,
  where,
  orderBy,
  runTransaction,
  serverTimestamp,
  Timestamp,
} from 'firebase/firestore';
import rsf from '../../helpers/firebase';
import toastr from 'toastr';
import {
  toDateFirebase,
  isNullish,
  getSeconds,
} from '../../helpers/sharedFunction';

const db = getFirestore(rsf.app);
const isDate = (date) => typeof date.getMonth === 'function';

export const callTransformer = (call, data, inbound = false) => {
  return {
    id: call.id,
    ...data,
    ...(inbound && { inbound }),
    ...(data.createdAt && {
      createdAt: toDateFirebase(call, data).toDate(),
    }),
    ...(data.updatedAt && {
      updatedAt: toDateFirebase(call, data, 'updatedAt').toDate(),
    }),
    ...(data.appointmentDate && {
      appointmentDate: toDateFirebase(call, data, 'appointmentDate').toDate(),
    }),
    ...(data.startDateCall && {
      startDateCall: toDateFirebase(call, data, 'startDateCall').toDate(),
    }),
    ...(data.endDateCall && {
      endDateCall: toDateFirebase(call, data, 'endDateCall').toDate(),
    }),
    ...(data.startDateCall &&
      data.endDateCall && {
        durationCall: getSeconds(
          toDateFirebase(call, data, 'startDateCall').toDate(),
          toDateFirebase(call, data, 'endDateCall').toDate(),
        ),
      }),
  };
};

function* createCallSaga(payload) {
  const newCall = payload.call;
  try {
    const callsRef = collection(db, 'calls');

    const callId = newCall.id;
    delete newCall.id;

    yield call(
      callId ? rsf.firestore.setDocument : rsf.firestore.addDocument,
      callId ? doc(callsRef, callId) : callsRef,
      {
        ...newCall,
        ...(newCall.appointmentDate && {
          appointmentDate: Timestamp.fromDate(
            new Date(newCall.appointmentDate),
          ),
        }),
        createdAt: serverTimestamp(),
      },
    );
    yield put(callsActions.createCallSuccess(newCall));
    toastr.success('Call created!', '');
  } catch (error) {
    yield put(callsActions.createCallFailure(error));
  }
}

function* createExtraCallSaga({ newCall, prevCallId }) {
  try {
    const functions = getFunctions();
    const createExtraCallFunction = httpsCallable(
      functions,
      'createExtraCall-createExtraCall',
    );
    const { data } = yield call(createExtraCallFunction, {
      newCall,
      prevCallId,
    });

    if (data.error) throw new Error(data.error.message);

    yield put(callsActions.createExtraCallSuccess());
    toastr.success(data.message, '');
  } catch (error) {
    yield put(callsActions.createExtraCallFailure(error));
    toastr.error(error.message, '');
  }
}

function* updateCallSaga({ call: updateCall }) {
  const user = yield select((state) => state.Auth.admin);

  const callRef = doc(db, 'calls', updateCall.id);
  delete updateCall.id;

  try {
    yield call(runTransaction, db, (transaction) => {
      return transaction.get(callRef).then((callSnap) => {
        if (!callSnap.exists) {
          throw Error('This call does not exist!');
        }
        const userId = callSnap.get('userId');
        if (userId !== '' && userId !== user.id) {
          throw Error('This call does not modified!');
        }

        transaction.update(callRef, {
          ...updateCall,
          ...(updateCall.createdAt && {
            createdAt: Timestamp.fromDate(new Date(updateCall.createdAt)),
          }),
          ...(updateCall.appointmentDate && {
            appointmentDate: Timestamp.fromDate(
              new Date(updateCall.appointmentDate),
            ),
          }),
          ...(updateCall.startDateCall && {
            startDateCall: Timestamp.fromDate(
              new Date(updateCall.startDateCall),
            ),
          }),
          ...(updateCall.endDateCall && {
            endDateCall: isDate(updateCall.endDateCall)
              ? Timestamp.fromDate(new Date(updateCall.endDateCall))
              : updateCall.endDateCall,
          }),
          updatedAt: serverTimestamp(),
        });
      });
    });
    yield put(callsActions.updateCallSuccess());
    toastr.success('Call updated!', '');
  } catch (error) {
    yield put(callsActions.updateCallFailure(error));
    toastr.error(error.message);
  }
}

function* fetchCallsSaga({ startDate, endDate, filters }) {
  try {
    const companyId = yield select((state) => state.Dashboard.companyId);
    const callsRef = rsf.firestore.createCollectionRefWithFilters(
      'calls',
      companyId,
      startDate,
      endDate,
      filters,
    );

    const callsSnap = yield call(rsf.firestore.getCollection, callsRef);

    let calls = [];

    callsSnap.forEach((call) => {
      const data = call.data();
      calls.push(callTransformer(call, data));
    });

    yield put(
      callsActions.fetchCallsSuccess(calls, startDate, endDate, filters),
    );
  } catch (error) {
    yield put(callsActions.fetchCallsFailure(error));
  }
}

function* fetchCallsByLeadSaga({ lead }) {
  try {
    const callsRef = query(
      collection(db, 'calls'),
      where('leadId', '==', lead.id),
      orderBy('createdAt', 'asc'),
    );
    const callsSnap = yield call(rsf.firestore.getCollection, callsRef);
    const calls = callsSnap.docs.map((call) =>
      callTransformer(call, call.data()),
    );

    yield put(callsActions.fetchCallsByLeadSuccess(calls));
  } catch (error) {
    yield put(callsActions.fetchCallsByLeadFailure(error));
  }
}

const createCallsRefWithFilters = (
  companyId,
  startDate,
  endDate,
  filters = null,
) => {
  const queryConditions = [
    where('endDateCall', '>=', startDate),
    where('endDateCall', '<=', endDate),
    where('companyId', '==', companyId),
    where('status', '==', 'closed'),
    orderBy('endDateCall', 'desc'),
  ];

  if (!filters || isNullish(filters))
    return query(collection(db, 'calls'), ...queryConditions);

  if (filters.sector && !filters.campaignIds) {
    queryConditions.push(where('sector', '==', filters.sector));
    return query(collection(db, 'calls'), ...queryConditions);
  }

  queryConditions.push(where('campaignId', 'in', filters.campaignIds));
  return query(collection(db, 'calls'), ...queryConditions);
};

function* fetchClosedCallsSaga({ startDate, endDate, filters }) {
  try {
    const companyId = yield select((state) => state.Dashboard.companyId);
    const callsRef = createCallsRefWithFilters(
      companyId,
      startDate,
      endDate,
      filters,
    );

    const callsSnap = yield call(rsf.firestore.getCollection, callsRef);
    const calls = callsSnap.docs.map((call) =>
      callTransformer(call, call.data()),
    );
    yield put(callsActions.fetchClosedCallsSuccess(calls));
  } catch (error) {
    yield put(callsActions.fetchClosedCallsFailure(error));
  }
}

const callSyncTransformer = (payload) => {
  let calls = [];

  payload.forEach((call) => {
    const data = call.data();

    calls.push(callTransformer(call, data));
  });

  return calls;
};

function* syncQueuedCallsSaga() {
  const companyId = yield select((state) => state.Dashboard.companyId);
  const isOperator = yield select(isOperatorSelector);
  const acquisitionOrderIds = yield select(userAcquisitionOrderIds);

  const queryConditions = [
    where('status', '==', 'new'),
    where('companyId', '==', companyId),
    orderBy('createdAt', 'desc'),
  ];

  if (isOperator && acquisitionOrderIds)
    queryConditions.push(
      where('acquisitionOrderId', 'in', acquisitionOrderIds),
    );

  const task = yield fork(
    rsf.firestore.syncCollection,
    query(collection(db, 'calls'), ...queryConditions),
    {
      successActionCreator: callsActions.syncQueuedCallsSuccess,
      failureActionCreator: callsActions.syncQueuedCallsFailure,
      transform: (payload) => callSyncTransformer(payload),
    },
  );

  yield take(types.RESET_CALL_STATE);
  yield cancel(task);
}

function* callSaga() {
  yield takeLatest(types.SYNC_QUEUED_CALLS.REQUEST, syncQueuedCallsSaga);
  yield all([
    takeEvery(types.FETCH_CALLS.REQUEST, fetchCallsSaga),
    takeLeading(types.CREATE_CALL.REQUEST, createCallSaga),
    takeLeading(types.CREATE_EXTRA_CALL.REQUEST, createExtraCallSaga),
    takeLeading(types.UPDATE_CALL.REQUEST, updateCallSaga),
    takeLeading(types.FETCH_CALLS_BY_LEAD.REQUEST, fetchCallsByLeadSaga),
    takeLeading(types.FETCH_CLOSED_CALLS.REQUEST, fetchClosedCallsSaga),
  ]);
}

export default callSaga;
